<template>
    <div>
        <v-card>
            <Breadcrumbs />
            <v-row style="padding: 0px 16px">
                <v-col cols="3" style="padding: 4px 10px">
                    <!-- PK副标的搜索 -->
                    <v-text-field
                        class=""
                        v-model="search"
                        outlined
                        dense
                        label="Search User"
                        single-line
                        hide-details
                    >
                    </v-text-field>
                </v-col>

                <v-col cols="5"></v-col>

                <v-col cols="4" class="text-right">
                    <v-btn
                        small
                        @click.stop="triggerDialog(1)"
                        color="primary"
                        class="mr-2"
                        :loading="loading"
                        >ADD USER</v-btn
                    ><v-btn
                        small
                        class="mr-2"
                        :loading="loading"
                        color="orange"
                        :disabled="profileData.length === 0"
                        @click="openProfileManagementDialog()"
                    >
                        Manage Profile
                    </v-btn>
                    <v-btn
                        small
                        @click="userHandler().load()"
                        :loading="loading"
                        color="blue-grey"
                        class="mr-2"
                        >Refresh</v-btn
                    >
                    <v-btn
                        small
                        @click.stop="batchDialog()"
                        :disabled="selectedUsers.length == 0"
                        :loading="loading"
                        color="red"
                    >
                        UPDATE PERMISSION
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :search="search"
                        dense
                        v-model="selectedUsers"
                        :headers="headers"
                        :items="listUser"
                        class="elevation-1"
                        :single-select="false"
                        show-select
                        :header-props="{ sortIcon: null }"
                        sort-by="created_at"
                    >
                        <template v-slot:item.is_active="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-switch
                                        :input-value="item.is_active"
                                        :loading="loading"
                                        dense
                                        small
                                        class="mt-0 pt-0"
                                        style="height: 20px"
                                        @change="
                                            (event) => toggleStatus(event, item)
                                        "
                                    ></v-switch>
                                </template>
                                <span>{{ item.is_active }}</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        small
                                        v-on="on"
                                        class="mr-2"
                                        @click="triggerDialog(2, item)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        small
                                        v-on="on"
                                        class="mr-2"
                                        @click="deleteConfirmation(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        small
                                        v-on="on"
                                        class="mr-2"
                                        @click="forceLogout(item)"
                                    >
                                        mdi-logout
                                    </v-icon>
                                </template>
                                <span>Force logout</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-dialog
                v-model="dialog.visible"
                :fullscreen="dialog.fullscreen"
                width="1000"
                :hide-overlay="true"
                :persistent="true"
            >
                <v-card class="pt-0">
                    <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)"
                        style="background: rgb(26, 57, 88)"
                    >
                        <v-toolbar-title class="text-h6 white--text pl-0">
                            {{ dialog.title }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn
                            color="white"
                            icon
                            @click="dialog.fullscreen = !dialog.fullscreen"
                        >
                            <v-icon>mdi-arrow-expand</v-icon>
                        </v-btn>
                        <v-btn
                            color="white"
                            icon
                            @click="dialog.visible = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>

                    <v-card-actions>
                        <v-row>
                            <v-col cols="6" sm="6" md="6" lg="6" xl="4">
                                <v-text-field
                                    ref="email"
                                    label="Email"
                                    :readonly="dialog.type == 2"
                                    v-model="dialog.email"
                                    hide-details="auto"
                                    :rules="[rules.required, rules.email]"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="6" sm="6" md="6" lg="6" xl="4">
                                <v-text-field
                                    :rules="[
                                        rules.min6,
                                        dialog.type == 1
                                            ? rules.pwdRequired
                                            : true,
                                    ]"
                                    label="Password"
                                    v-model="dialog.password"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="6" sm="6" md="6" lg="6" xl="4">
                                <v-text-field
                                    label="Mobile"
                                    v-model="dialog.mobile"
                                    hide-details="auto"
                                    :rules="[rules.mobile]"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="6" sm="6" md="6" lg="6" xl="4">
                                <v-text-field
                                    label="Username"
                                    v-model="dialog.username"
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="6" sm="6" md="6" lg="6" xl="4">
                                <v-text-field
                                    label="First Name"
                                    v-model="dialog.firstname"
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="6" sm="6" md="6" lg="6" xl="4">
                                <v-text-field
                                    label="Last Name"
                                    v-model="dialog.lastname"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="6" sm="6" md="6" lg="6" xl="4">
                                <v-autocomplete
                                    label="Select Permission Role"
                                    v-model="dialog.role"
                                    :items="permissionList"
                                    @change="(event) => changeRole(event)"
                                    :rules="[rules.required]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="6"
                                sm="6"
                                md="6"
                                lg="6"
                                xl="4"
                                style="display: flex; align-items: center"
                            >
                                <v-icon
                                    class="mr-2"
                                    @click="
                                        toEditPermission(dialog.permission_id)
                                    "
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    class="mr-2"
                                    @click="getPermissionAction"
                                >
                                    mdi-refresh
                                </v-icon>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <p>Permission</p>
                                <div
                                    @click="
                                        toEditPermission(dialog.permission_id)
                                    "
                                >
                                    <JsonEditor
                                        ref="jsonEditor"
                                        v-model="dialog.permission"
                                        readOnly="nocursor"
                                        v-if="dialog.loadJson"
                                    />
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-actions>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialog.visible = false"
                            :disabled="dialog.loading"
                            >Cancel</v-btn
                        >
                        <v-btn
                            color="green darken-1"
                            v-if="dialog.type == 1"
                            text
                            @click="userHandler().create()"
                            :loading="loading"
                        >
                            <span>Add</span>
                        </v-btn>
                        <v-btn
                            color="green darken-1"
                            v-if="dialog.type == 2"
                            text
                            @click="userHandler().update()"
                            :loading="loading"
                        >
                            <span>Update</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="batch.visible"
                :fullscreen="batch.fullscreen"
                width="1000"
                :hide-overlay="true"
                :persistent="true"
            >
                <v-card class="pt-0">
                    <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)"
                        style="background: rgb(26, 57, 88)"
                    >
                        <v-toolbar-title class="text-h6 white--text pl-0">
                            {{ batch.title }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn
                            color="white"
                            icon
                            @click="batch.fullscreen = !batch.fullscreen"
                        >
                            <v-icon>mdi-arrow-expand</v-icon>
                        </v-btn>
                        <v-btn
                            color="white"
                            icon
                            @click="batch.visible = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>

                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                <v-autocomplete
                                    label="Select Permission Role"
                                    v-model="batch.role"
                                    :items="permissionList"
                                    @change="(event) => changeRole(event)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <p>Permission</p>
                                <JsonEditor
                                    ref="jsonEditor"
                                    v-if="batch.loadJson"
                                    v-model="batch.permission"
                                />
                            </v-col>
                        </v-row>
                    </v-card-actions>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="batch.visible = false"
                            >Cancel</v-btn
                        >
                        <v-btn
                            color="green darken-1"
                            text
                            @click="batchUpdatePermission()"
                            :loading="loading"
                        >
                            <span>Update</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="profileDialog"
                width="1200"
                :hide-overlay="true"
                :persistent="true"
            >
                <v-card class="pt-0">
                    <v-app-bar
                        flat
                        color="rgba(0, 0, 0, 0)"
                        style="background: rgb(26, 57, 88)"
                    >
                        <v-toolbar-title class="text-h6 white--text pl-0">
                            User Profile Management
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn
                            color="white"
                            icon
                            @click="profileDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>

                    <v-card-actions>
                        <v-row
                            ><v-col cols="12" class="pb-0">
                                <v-alert
                                    dense
                                    outlined
                                    type="info"
                                    hide-details
                                    class="ma-0"
                                >
                                    Please choose your source and target user
                                    and select all profiles that will be
                                    inherited as target user's working profiles.
                                </v-alert>
                            </v-col>

                            <v-col cols="12">
                                <v-autocomplete
                                    label="From User"
                                    v-model="fromUser"
                                    :items="
                                        listUser.filter(
                                            (item) => item.id !== toUser
                                        )
                                    "
                                    hide-details
                                    style="width: 25%"
                                    item-text="username"
                                    item-value="id"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                v-show="fromUserProfiles['home'].length"
                                ><v-row
                                    ><v-col cols="12" class="pb-0"
                                        ><b>- Home:</b>
                                        <v-tooltip right>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ml-4"
                                                    color="primary"
                                                    x-small
                                                    dense
                                                    fab
                                                    @click="
                                                        selectAll(
                                                            'home',
                                                            'from'
                                                        )
                                                    "
                                                    ><v-icon
                                                        >mdi-check-all</v-icon
                                                    ></v-btn
                                                >
                                            </template>
                                            <span>Click to select all</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col
                                        cols="3"
                                        v-for="item in fromUserProfiles['home']"
                                    >
                                        <v-checkbox
                                            v-model="profileForm[item.id]"
                                            hide-details
                                            style="margin: 0; padding: 0"
                                        >
                                            <template v-slot:label>
                                                <div
                                                    v-if="
                                                        nameCheck(
                                                            item.id,
                                                            item.profile_name,
                                                            'home',
                                                            'from'
                                                        )
                                                    "
                                                    style="color: red"
                                                >
                                                    {{ item.profile_name }}
                                                </div>
                                                <div v-else>
                                                    {{ item.profile_name }}
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col
                                cols="12"
                                v-show="fromUserProfiles['alert'].length"
                                ><v-row
                                    ><v-col cols="12" class="pb-0"
                                        ><b>- Alert:</b>
                                        <v-tooltip right>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ml-4"
                                                    color="primary"
                                                    x-small
                                                    dense
                                                    fab
                                                    @click="
                                                        selectAll(
                                                            'alert',
                                                            'from'
                                                        )
                                                    "
                                                    ><v-icon
                                                        >mdi-check-all</v-icon
                                                    ></v-btn
                                                >
                                            </template>
                                            <span>Click to select all</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col
                                        cols="3"
                                        v-for="item in fromUserProfiles[
                                            'alert'
                                        ]"
                                    >
                                        <v-checkbox
                                            v-model="profileForm[item.id]"
                                            hide-details
                                            style="margin: 0; padding: 0"
                                        >
                                            <template v-slot:label>
                                                <div
                                                    v-if="
                                                        nameCheck(
                                                            item.id,
                                                            item.profile_name,
                                                            'alert',
                                                            'from'
                                                        )
                                                    "
                                                    style="color: red"
                                                >
                                                    {{ item.profile_name }}
                                                </div>
                                                <div v-else>
                                                    {{ item.profile_name }}
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12">
                                <v-autocomplete
                                    label="To User"
                                    v-model="toUser"
                                    hide-details
                                    :items="
                                        listUser.filter(
                                            (item) => item.id !== fromUser
                                        )
                                    "
                                    style="width: 25%"
                                    item-text="username"
                                    item-value="id"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                v-show="toUserProfiles['home'].length"
                                ><v-row
                                    ><v-col cols="12" class="pb-0"
                                        ><b>- Home:</b>
                                        <v-tooltip right>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ml-4"
                                                    color="primary"
                                                    x-small
                                                    dense
                                                    fab
                                                    @click="
                                                        selectAll('home', 'to')
                                                    "
                                                    ><v-icon
                                                        >mdi-check-all</v-icon
                                                    ></v-btn
                                                >
                                            </template>
                                            <span>Click to select all</span>
                                        </v-tooltip></v-col
                                    >
                                    <v-col
                                        cols="3"
                                        v-for="item in toUserProfiles['home']"
                                    >
                                        <v-checkbox
                                            v-model="profileForm[item.id]"
                                            hide-details
                                            style="margin: 0; padding: 0"
                                        >
                                            <template v-slot:label>
                                                <div
                                                    v-if="
                                                        nameCheck(
                                                            item.id,
                                                            item.profile_name,
                                                            'home',
                                                            'to'
                                                        )
                                                    "
                                                    style="color: red"
                                                >
                                                    {{ item.profile_name }}
                                                    <v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                            }"
                                                        >
                                                            <v-icon
                                                                small
                                                                v-on="on"
                                                                class="ml-2"
                                                                color="red"
                                                            >
                                                                mdi-alert
                                                            </v-icon>
                                                        </template>
                                                        <span
                                                            >This profile will
                                                            be overwritten by
                                                            the profile that has
                                                            the same name.</span
                                                        >
                                                    </v-tooltip>
                                                </div>
                                                <div v-else>
                                                    {{ item.profile_name }}
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col
                                cols="12"
                                v-show="toUserProfiles['alert'].length"
                                ><v-row
                                    ><v-col cols="12" class="pb-0"
                                        ><b>- Alert:</b>
                                        <v-tooltip right>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ml-4"
                                                    color="primary"
                                                    x-small
                                                    dense
                                                    fab
                                                    @click="
                                                        selectAll('alert', 'to')
                                                    "
                                                    ><v-icon
                                                        >mdi-check-all</v-icon
                                                    ></v-btn
                                                >
                                            </template>
                                            <span>Click to select all</span>
                                        </v-tooltip></v-col
                                    >
                                    <v-col
                                        cols="3"
                                        v-for="item in toUserProfiles['alert']"
                                    >
                                        <v-checkbox
                                            v-model="profileForm[item.id]"
                                            hide-details
                                            style="margin: 0; padding: 0"
                                        >
                                            <template v-slot:label>
                                                <div
                                                    v-if="
                                                        nameCheck(
                                                            item.id,
                                                            item.profile_name,
                                                            'alert',
                                                            'to'
                                                        )
                                                    "
                                                    style="color: red"
                                                >
                                                    {{ item.profile_name
                                                    }}<v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                            }"
                                                        >
                                                            <v-icon
                                                                small
                                                                v-on="on"
                                                                class="ml-2"
                                                                color="red"
                                                            >
                                                                mdi-alert
                                                            </v-icon>
                                                        </template>
                                                        <span
                                                            >This profile will
                                                            be overwritten by
                                                            the profile that has
                                                            the same name.</span
                                                        >
                                                    </v-tooltip>
                                                </div>
                                                <div v-else>
                                                    {{ item.profile_name }}
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-actions>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="profileDialog = false"
                            >Cancel</v-btn
                        >
                        <v-btn
                            color="green darken-1"
                            text
                            @click="updateUserProfile"
                            :loading="loading"
                        >
                            <span>Update</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
        <DeleteConfirmDialog
            :confirmPopover="confirmPopover"
            type="User"
            :name="selectedDeletion.username"
            :loading="loading"
            @close="
                () => {
                    confirmPopover = false;
                }
            "
            @confirm="deleteUser"
        ></DeleteConfirmDialog>
    </div>
</template>
<script>
import Breadcrumbs from "@components/Breadcrumbs";
import JsonEditor from "@components/ComponentJsonEditor";
import { mapState, mapActions, mapMutations } from "vuex";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";
import deepClone from "deep-clone";
import DeleteConfirmDialog from "@components/ComponentDeleteConfirmDialog";
import { rules } from "@assets/js/validator";
import validator from "@assets/js/validator";

export default {
    components: { Breadcrumbs, JsonEditor, DeleteConfirmDialog },
    mixins: [helper, snackbar],
    data() {
        return {
            search: "",
            btnLoading: false,
            showPwd: true,
            batch: {
                fullscreen: false,
                visible: false,
                title: "",
                role: "",
                permission: [],
                loadJson: false,
                loading: false,
            },
            dialog: {},
            defaultDialog: {
                id: -1,
                brokerId: -1,
                fullscreen: false,
                type: 0,
                visible: false,
                title: "",
                loading: false,
                email: null,
                password: null,
                username: null,
                firstname: null,
                lastname: null,
                permission: {},
                role: null,
                loadJson: false,
                isActive: true,
                loadingActive: false,
                loadingLogin: false,
                createdAt: null,
                updatedAt: null,
                lastLogin: null,
                mobile: null,
            },
            selectedRole: "",
            selectedUsers: [],
            selectedDeletion: {},
            confirmPopover: false,
            profileDialog: false,
            rules: rules,
            fromUser: null,
            toUser: null,
            profileForm: [],
            conflictProfile: [],
        };
    },
    computed: {
        ...mapState("UserManagement", [
            "listUser",
            "loading",
            "headers",
            "permissionData",
            "permissionList",
            "uploaded",
            "profileData",
        ]),
        filterBroker() {
            let brokers = this.$store.state.brokerMap;
            brokers[brokers.length] = {
                broker_id: -1,
                broker: "ALL",
                book: ["*"],
                bookExcept: [],
            };
            return brokers;
        },
        fromUserProfiles() {
            const useProfiles = this.profileData.filter((data) => {
                return data.user_id === this.fromUser;
            });
            const homeProfiles = useProfiles.filter(
                (data) => data.meta_value === "home"
            );
            const alertProfiles = useProfiles.filter(
                (data) => data.meta_value === "alert"
            );
            return { home: homeProfiles, alert: alertProfiles };
        },
        toUserProfiles() {
            const useProfiles = this.profileData.filter((data) => {
                return data.user_id === this.toUser;
            });
            const homeProfiles = useProfiles.filter(
                (data) => data.meta_value === "home"
            );
            const alertProfiles = useProfiles.filter(
                (data) => data.meta_value === "alert"
            );
            return { home: homeProfiles, alert: alertProfiles };
        },
    },
    methods: {
        ...mapActions("UserManagement", [
            "getUsersAction",
            "postUsersAction",
            "putUsersAction",
            "changeUserStatusAction",
            "getPermissionAction",
            "batchUpdateAction",
            "deleteUserAction",
            "forceLogoutAction",
            "getProfileAction",
            "putProfileAction",
        ]),
        ...mapMutations("UserManagement", ["UPDATE_UPLOADED"]),
        /**
         * [triggerDialog description]
         * @param   {[type]}  type  [type description]
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        triggerDialog(type, data = null) {
            if (type == 1) {
                this.dialog = deepClone(this.defaultDialog);
                this.dialog.type = type;
                this.dialog.visible = true;
                this.dialog.title = "Add New User";
            }

            if (type == 2) {
                this.dialog.type = type;
                this.dialog.visible = true;
                this.dialog.id = data.id;
                this.dialog.title = "Update Existing User";
                this.dialog.email = data.email;
                this.dialog.password = null;
                this.dialog.username = data.username;
                this.dialog.firstname = data.first_name;
                this.dialog.lastname = data.last_name;
                this.dialog.permission = data.permission;
                this.dialog.loadJson = false;
                this.dialog.isActive = data.is_active;
                this.dialog.brokerId = data.broker_id;
                this.dialog.permission_id = data.permission_id;
                this.dialog.mobile = data.mobile;
                this.dialog.role = data.role;
            }

            setTimeout(() => {
                this.$nextTick(() => {
                    this.dialog.loadJson = true;
                });
            }, 500);
        },
        /**
         * Change user status
         * @param   {[type]}  event  [event description]
         * @param   {[type]}  item   [item description]
         * @return  {[type]}         [return description]
         */
        toggleStatus(event, item) {
            let params = item;
            params["is_active"] = event;
            this.changeUserStatusAction(params);
        },
        /**
         * User handler that helps to call user api to load, create or update user
         * @return  {[type]}  [return description]
         */
        userHandler() {
            let self = this;
            return {
                // load user
                load() {
                    self.getPermissionAction();
                    self.getProfileAction();
                    self.getUsersAction();
                },
                // create user
                create() {
                    if (self.checkMissingFiled(self.dialog) == false) {
                        return;
                    }
                    self.dialog.username = validator.removeAllWhiteSpace(
                        self.dialog.username
                    );
                    self.dialog.firstname = validator.cleanUpStringData(
                        self.dialog.firstname
                    );
                    if (self.dialog.lastname != null) {
                        self.dialog.lastname = validator.cleanUpStringData(
                            self.dialog.lastname
                        );
                    } else {
                        self.dialog.lastname = "";
                    }
                    if (self.dialog.mobile != null) {
                        self.dialog.mobile = validator.removeAllWhiteSpace(
                            self.dialog.mobile
                        );
                    }
                    self.postUsersAction(self.dialog);
                },
                // update user
                update() {
                    if (self.checkMissingFiled(self.dialog) == false) {
                        return;
                    }
                    self.dialog.username = validator.removeAllWhiteSpace(
                        self.dialog.username
                    );
                    self.dialog.firstname = validator.cleanUpStringData(
                        self.dialog.firstname
                    );
                    if (self.dialog.lastname != null) {
                        self.dialog.lastname = validator.cleanUpStringData(
                            self.dialog.lastname
                        );
                    } else {
                        self.dialog.lastname = "";
                    }
                    if (self.dialog.mobile != null) {
                        self.dialog.mobile = validator.removeAllWhiteSpace(
                            self.dialog.mobile
                        );
                    }
                    self.putUsersAction(self.dialog);
                },
            };
        },
        /**
         * Open bath update dialog
         * @return {[type]} [description]
         */
        batchDialog() {
            // load permission on dialog open
            this.getPermissionAction();

            this.batch.role = null;
            this.batch.visible = true;
            this.batch.permission_id = null;
            this.batch.permission = {};
            this.batch.title = "Batch Permission Editor";
            this.batch.loadJson = false;

            setTimeout(() => {
                this.$nextTick(() => {
                    this.batch.loadJson = true;
                });
            }, 500);
        },
        /**
         * Batch Update permission
         * @return  {[type]}  [return description]
         */
        batchUpdatePermission() {
            if (this.batch.permission_id == null) {
                this.snackBarDanger(
                    "Please select a permission role for selected users"
                );
                return;
            }
            let data = {
                permission_id: this.batch.permission_id,
                selectedUsers: this.selectedUsers.map((item) => item.id),
            };
            this.batchUpdateAction(data);
        },
        /**
         * open up a new tab with current permission config for editing
         * @return  {[type]}  [return description]
         */
        toEditPermission(permissionId) {
            let routeData = this.$router.resolve({
                name: "AccountPermission",
                query: { permission_id: permissionId },
            });
            window.open(routeData.href, "_blank");
        },
        /**
         * change the permission config in the open dialog
         * @return  {[type]}  [return description]
         */
        changeRole(name) {
            const selectedPermission = this.permissionData.filter(
                (item) => item.permission_name == name
            )[0];
            this.dialog.permission_id = selectedPermission.id;
            this.dialog.permission = selectedPermission.permission;
            this.batch.permission_id = selectedPermission.id;
            this.batch.permission = selectedPermission.permission;
        },
        deleteConfirmation(userInfo) {
            this.selectedDeletion = userInfo;
            this.confirmPopover = true;
        },
        forceLogout(userInfo) {
            this.forceLogoutAction({ id: userInfo.id });
        },
        deleteUser() {
            this.deleteUserAction({ id: this.selectedDeletion.id });
        },
        checkMissingFiled(dialog) {
            const fieldMissing =
                !!dialog.email &&
                !!dialog.firstname &&
                !!dialog.username &&
                !!dialog.permission_id;

            if (fieldMissing == false) {
                this.snackBarDanger(
                    "Please complete the form before submitting."
                );
                return false;
            } else {
                if (dialog.type == 2) {
                    return true;
                } else {
                    if (!dialog.password) {
                        this.snackBarDanger(
                            "Please complete the form before submitting."
                        );
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        },
        nameCheck(id, profileName, type, target) {
            if (target === "from") {
                const result =
                    this.profileForm[
                        this.toUserProfiles[type].find(
                            (i) => i.profile_name === profileName
                        )?.id
                    ] && this.profileForm[id];
                if (result === true) {
                    if (!this.conflictProfile.includes(id.toString())) {
                        this.conflictProfile.push(id.toString());
                    }
                } else if (result === false) {
                    const test = this.conflictProfile.filter(
                        (i) => i !== id.toString()
                    );
                    if (
                        JSON.stringify(test) !==
                        JSON.stringify(this.conflictProfile)
                    ) {
                        this.conflictProfile = test;
                    }
                }

                return result;
            } else {
                const result =
                    this.profileForm[
                        this.fromUserProfiles[type].find(
                            (i) => i.profile_name === profileName
                        )?.id
                    ] && this.profileForm[id];
                return result;
            }
        },
        selectAll(type, target) {
            const copy = deepClone(this.profileForm);
            if (target === "from") {
                this.fromUserProfiles[type].map(
                    (item) => (copy[item.id] = true)
                );
            } else {
                this.toUserProfiles[type].map((item) => (copy[item.id] = true));
            }
            this.profileForm = copy;
        },
        openProfileManagementDialog() {
            this.fromUser = null;
            this.toUser = null;
            this.profileDialog = true;
        },
        updateUserProfile() {
            if (this.fromUser === null || this.toUser === null) {
                this.snackBarDanger("Target or Source user is missing");
            } else {
                const allSelectedProfiles = Object.keys(
                    this.profileForm
                ).filter((key) => this.profileForm[key]);

                let deletion = [];
                let addition = [];
                this.toUserProfiles.alert.map((item) => {
                    if (!this.profileForm[item.id]) {
                        deletion.push(item.id);
                    }
                });
                this.toUserProfiles.home.map((item) => {
                    if (!this.profileForm[item.id]) {
                        deletion.push(item.id);
                    }
                });

                // console.log("from:", this.fromUser, "to:", this.toUser);
                // console.log("from user has:", this.fromUserProfiles);
                // console.log("to user has:", this.toUserProfiles);
                // console.log(this.profileForm);
                // console.log(allSelectedProfiles);
                // console.log(deletion);
                // console.log(this.conflictProfile);

                allSelectedProfiles.map((item) => {
                    if (
                        this.profileData.find(
                            (i) => i.id.toString() === item.toString()
                        )?.user_id === this.fromUser
                    ) {
                        if (!this.conflictProfile.includes(item)) {
                            addition.push(item);
                        } else {
                            // console.log(item);
                            const locatedProfile = this.profileData.find(
                                (i) => i.id.toString() === item
                            );
                            // console.log(locatedProfile.id);
                            const conflictProfileId = this.profileData.find(
                                (i) =>
                                    i.user_id === this.toUser &&
                                    i.meta_value ===
                                        locatedProfile.meta_value &&
                                    i.profile_name ===
                                        locatedProfile.profile_name
                            )?.id;
                            // console.log(conflictProfileId);
                            deletion.push(conflictProfileId);
                            addition.push(locatedProfile.id);
                        }
                    }
                });

                this.putProfileAction({
                    toUser: this.toUser,
                    addition: addition,
                    deletion: deletion,
                });
            }
        },
    },
    watch: {
        /**
         * Display permission data according the users' selection
         * @param   {[type]}  nv  [nv description]
         * @param   {[type]}  ov  [ov description]
         * @return  {[type]}      [return description]
         */
        selectedRole(nv, ov) {
            const data = JSON.parse(localStorage.getItem("permissionData"));
            this.batch.permission = data
                .filter((item) => item.role == nv)
                .map((item) => item.permission)[0];
        },
        permissionData() {
            this.getUsersAction();
        },
        uploaded(nv) {
            if (nv === true) {
                this.getProfileAction();
                this.batch.visible = false;
                this.dialog.visible = false;
                this.confirmPopover = false;
                this.profileDialog = false;
                this.getUsersAction();
                this.UPDATE_UPLOADED(false);
            }
        },
        toUser() {
            this.profileForm = [];
        },
        fromUser() {
            this.profileForm = [];
        },
    },
    mounted() {
        this.getPermissionAction();
        this.getProfileAction();
        this.dialog = deepClone(this.defaultDialog);
    },
};
</script>
